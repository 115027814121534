<template>
    <Introduction
        width="m"
        :title="$t('footer.actions.download_with_prices')"
        text="De download wordt gestart..."
        :actions="[]"
    />
</template>

<script>
import Introduction from '@/components/Introduction.vue';

export default {
    components: {
        Introduction,
    },
    mounted() {
        setTimeout(this.startDownload, 250);
    },
    methods: {
        startDownload() {
            const url = this.cdnurl(this.data.stock_download_auth);
            if (url) {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.download = true;
                a.href = url;
                a.click();
            }
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>
